import React from "react";
import { navigate } from "gatsby-link";
// import GoogleMapReact from 'google-map-react';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
// import { default as mapboxgl } from 'mapbox-gl';

import ReactMapboxGl, {
//  Layer,
//  Feature,
  Marker,
  ZoomControl,
} from "react-mapbox-gl";
import Layout from "../../components/Layout";
import { Header } from "../../components/Header";

import "mapbox-gl/dist/mapbox-gl.css";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiZGFuYnVrIiwiYSI6ImNraWoyam9kdTFpeHgyeXF1YThreDFqdTUifQ.r-xXR8NIkmR1qiYHDYn8Gg",
});

const LAT = 51.5194541;
const LNG = -0.2076212;

// 51.51948,-0.2101438

const encode = (obj) => {
  let r = "";
  for (const [k, v] of Object.entries(obj)) {
    if (r !== "") r += "&";
    r += `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
  }
  return r;
};

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <Header
          title="Contact"
          subheading=""
          image="/img/kailash-three-bottles.webp"
        />
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  Contact
                </h2>
                <div className="content columns">
                  <div className="column">
                    <div>
                      Address:
                      <br />
                      <a href={`geo:${LAT},${LNG}`}>
                        <span style={{ marginLeft: "1em" }}>
                          Portobello Road Market.
                        </span>
                        <br />
                        <span style={{ marginLeft: "1em" }}>London,</span>
                        <br />
                        <span style={{ marginLeft: "1em" }}>NW11 1AN</span>
                      </a>
                    </div>
                    <div>
                      Telephone:
                      <br />
                      <span style={{ marginLeft: "1em" }}>
                        <a href="tel:+447412120155">+44 7412 120155</a>
                      </span>
                    </div>
                    <div>
                      email:
                      <br />
                      <span style={{ marginLeft: "1em" }}>
                        <a href="info@kailash.org.uk">info@kailash.org.uk</a>
                      </span>
                    </div>
                  </div>
                  <div className="column">
                    <span>
                      Open 10am to 5pm:-
                      <br />
                      <span style={{ marginLeft: "1em" }}>Fri</span><br/>
                      <span style={{ marginLeft: "2em", display: 'block' }}>
                        Location varies in Portobello Road so please ring for details +447412120155
                      </span>
                      <br />
                      <span style={{ marginLeft: "1em" }}>Sat</span><br/>
                      <span style={{ marginLeft: "2em", display: 'block' }}>
                        Pitch 159 Portobello Road Market, near Acklam Village Food Market and Graffik Gallery London.
                      </span>
                      <br />
                      <span style={{ marginLeft: "1em" }}>Sun</span><br/>
                      <span style={{ marginLeft: "2em", display: 'block' }}>
                        Portobello Green Market, under the Westway Flyover, opposite Makkan Cafe.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="section">
                <Map
                  style={`mapbox://styles/mapbox/streets-v11`}
                  containerStyle={{
                    height: "50vh",
                    width: "50vw",
                  }}
                  zoom={[16]}
                  center={[LNG, LAT]}
                >
                  <Marker coordinates={[LNG, LAT]} anchor="bottom">
                    <img alt="" src="/img/kailash-map-K-marker.png" />
                  </Marker>
                  <ZoomControl />
                </Map>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
