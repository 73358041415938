import React from 'react';

export const formatNumber = number => {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(number);
}

const defaultProduct = { sku: 'default', quantity: -1 };
const defaultProducts = { default: {
    frontmatter: {
        title: 'title',
        details: {
            price: '-0.01',

        }
    }
}};

const getVariant = (vArr, code) => {
    return vArr.find((val) => val.code === code)
};

const getVariantName = (vArr, code) => {
    return getVariant(vArr, code).name;
}

export const CheckoutItem = ({product = defaultProduct, products = defaultProducts, increaseItem, decreaseItem, removeItem}) => {
    // console.dir()
    const variant = getVariant(products[product.sku].variants, product.variant);
    console.dir(variant);
    const price = 'price' in variant ? variant.price : products[product.sku].price;
    return (
        <div className="columns is-mobile is-centered">
            <div className="column is-one-fifth">{product.sku}-{product.variant}</div>
            <div className="column is-two-fifth">{products[product.sku].title} ({getVariantName(products[product.sku].variants, product.variant)})</div>
            <div className="column is-one-fifth">{product.quantity} (<span onClick={() => increaseItem(product.sku, product.variant)}>+</span>|<span onClick={() => decreaseItem(product.sku, product.variant)}>-</span>) @ {formatNumber(price)}</div>
            <div className="column is-one-fifth">
                <small></small> <b>{formatNumber(price * product.quantity)}</b>
            </div>
        </div>
     );
}
 
// export default CheckoutItem;