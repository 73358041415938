import React from 'react';
// import netlifyIdentity from 'netlify-identity-widget';

import Layout from '../../components/Layout';
import { PostroomList } from '../../components/PostroomList';

// export default function Protected() {
// export default function () {
  // const user = netlifyIdentity.currentUser();
  // console.log({ user });

class Postroom extends React.Component {
  /* constructor(props) {
    super(props);
  } */

  renderOLD () {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              
            </div>
          </div>
        </section>
      </Layout>
    );
  }

  render() {
    return (
        <Layout>
            <div className="container">
              <PostroomList />
            </div>
        </Layout>
    );
  }
};

export default Postroom;